import { Component } from "react";
import styled from "styled-components";
import Row from "../Row";
import Gutter from "../Gutter";
import { graphql } from "gatsby";

import { Font, media, rem, Color } from "../../utils/style";

const Content = styled.div.attrs({
  className: "col-12 col-md-7 offset-md-1",
})`
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;

  ${media.mobile`
    margin-bottom: 30px;
  `};
`;

const BioAvatar = styled.div`
  position: relative;
  width: 114px;
  height: 114px;
  overflow: hidden;
  border-radius: 114px;
  float: left;

  ${media.mobile`
    width: 84px;
    height: 84px;
    align-self: start;
  `} img {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
  }
`;

const BioContent = styled.div`
  float: left;
  padding-left: 40px;
  width: calc(100% - 114px);
  ${Font.dutch}
  color: ${Color.ritualBlue};
  font-size: ${rem(20)};
  line-height: ${rem(30)};

  ${media.mobile`
    width: calc(100% - 84px);
    padding-left: 14px;
    padding-top: 0;
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}

  p {
    font: inherit;
    color: inherit;
    margin: 0;
  }
`;

const Arrow = styled.div`
  position: absolute;
  bottom: -48px;
  left: 85px;

  svg {
    transform: rotate(-10deg);
  }

  ${media.mobile`
    display: none;
  `};
`;

export default class ArticleInterviewerBio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annotationTop: 0,
    };
  }

  setAnnotationTop(top) {
    this.setState({
      annotationTop: top,
    });
  }

  render() {
    let { interviewerName, bio, avatar } = this.props.data;
    return (
      <Row>
        <Gutter />
        <Content>
          <BioAvatar>
            <img src={avatar.file.url} alt={interviewerName} />
          </BioAvatar>
          <Arrow>
            <svg
              width="112px"
              height="46px"
              viewBox="0 0 112 46"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <title />
              <desc />
              <defs>
                <polygon
                  id="path-1"
                  points="0.00030939968 -6.21968775e-05 111.051 -6.21968775e-05 111.051 45.8388 0.00030939968 45.8388"
                />
              </defs>
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="Group-3">
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <g id="Clip-2" />
                  <path
                    d="M109.1098,8.0742 C107.3778,7.2112 105.2778,7.9182 104.4178,9.6482 C93.8438,30.8862 70.7968,42.6912 49.6148,37.7122 C30.2168,33.1552 20.6088,16.4232 17.1898,8.8902 L26.8608,11.5052 C27.1668,11.5872 27.4738,11.6272 27.7758,11.6272 C29.3188,11.6272 30.7318,10.5992 31.1528,9.0392 C31.6578,7.1732 30.5538,5.2522 28.6878,4.7472 L11.5468,0.1132 C11.5158,0.1042 11.4858,0.1052 11.4548,0.0972 C11.3308,0.0672 11.2068,0.0532 11.0808,0.0362 C10.9758,0.0232 10.8708,0.0052 10.7658,0.0012 C10.6448,-0.0038 10.5258,0.0072 10.4058,0.0142 C10.2968,0.0222 10.1898,0.0252 10.0838,0.0422 C9.9698,0.0602 9.8598,0.0922 9.7498,0.1212 C9.6428,0.1492 9.5358,0.1732 9.4328,0.2112 C9.3258,0.2502 9.2238,0.3032 9.1208,0.3532 C9.0228,0.4002 8.9238,0.4432 8.8308,0.4992 C8.7318,0.5582 8.6418,0.6302 8.5488,0.6992 C8.4618,0.7642 8.3718,0.8262 8.2908,0.8992 C8.2078,0.9742 8.1348,1.0592 8.0588,1.1422 C7.9798,1.2282 7.8998,1.3122 7.8298,1.4062 C7.7668,1.4912 7.7138,1.5862 7.6578,1.6782 C7.5918,1.7852 7.5258,1.8912 7.4718,2.0062 C7.4588,2.0342 7.4398,2.0572 7.4268,2.0862 L0.2958,18.3482 C-0.4802,20.1192 0.3248,22.1832 2.0948,22.9602 C2.5528,23.1602 3.0298,23.2552 3.4988,23.2552 C4.8458,23.2552 6.1298,22.4722 6.7068,21.1602 L10.8268,11.7652 C14.7908,20.4162 25.7948,39.3062 48.0138,44.5262 C51.7828,45.4122 55.5988,45.8392 59.4048,45.8392 C80.1478,45.8392 100.5398,33.1432 110.6828,12.7672 C111.5458,11.0372 110.8398,8.9352 109.1098,8.0742"
                    id="Fill-1"
                    fill="#FFDD17"
                    mask="url(#mask-2)"
                  />
                </g>
              </g>
            </svg>
          </Arrow>
          <BioContent
            dangerouslySetInnerHTML={{ __html: bio.childMarkdownRemark.html }}
          />
        </Content>
      </Row>
    );
  }
}

export const myQuery = graphql`
  fragment interviewerBioContentBlock on ContentfulInterviewBio {
    interviewerName
    bio {
      childMarkdownRemark {
        html
      }
    }
    avatar {
      file {
        url
      }
    }
  }
`;
