
import { Component } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Utils
import { media, Color } from "../../utils/style";
import $ from "jquery";

// Components
import Row from "../Row";
import GutterElement from "./GutterElement";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Styled Elements
const ImageModule = styled(Row)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ImageCol = styled.div.attrs({
  className: "col-12 col-md-7 offset-md-1",
})``;

const ImageWrapper = styled.div`
  border: 4px solid ${Color.ritualBlue};
  padding: 25px 35px;

  ${media.mobile`
    padding: 10px 15px;
    border-width: 2px;
  `} img {
    display: block;
    width: 100%;
    margin: 0;
  }
`;

export default class ArticleStandaloneImage extends Component {
  constructor(props) {
    super(props);
    this.annotation = null;
    this.imageRef = null;
    this.state = {
      annotationTop: 0,
      hideGutter: false,
    };
  }

  setAnnotationTop(top) {
    this.setState({
      annotationTop: top + $(this.imageRef).outerHeight(), // TODO: Remove jQuery usage
    });
  }

  setHideGutter(hideGutter) {
    this.setState({
      hideGutter: hideGutter,
    });
  }

  render() {
    let {
      data: { image, gutterItem },
      gutterContent,
    } = this.props;
    let firstGutterItem = gutterItem ? gutterItem[0] : null;
    let { annotationTop, hideGutter } = this.state;
    return (
      <ImageModule>
        <GutterElement
          {...firstGutterItem}
          hideGutter={hideGutter}
          top={annotationTop}
          overrideContent={gutterContent}
          className="d-md-block"
        />
        <ImageCol>
          <ImageWrapper ref={(r) => (this.imageRef = r)}>
            <GatsbyImage
              image={getImage(image)}
              alt={image.description}
              style={{
                maxWidth: "100%",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
            />
          </ImageWrapper>
        </ImageCol>
      </ImageModule>
    );
  }
}

export const myQuery = graphql`
  fragment standaloneImageBlock on ContentfulContentStandaloneImage {
    title
    image {
      description
      file {
        url
      }
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: DOMINANT_COLOR
        width: 960
        quality: 90
      )
    }
    gutterItem {
      __typename
      ... on ContentfulGutterAnnotation {
        ...gutterSummary
      }
      ... on ContentfulGutterCta {
        ...gutterCtaSummary
      }
      ... on ContentfulGutterImage {
        ...gutterImageSummary
      }
    }
  }
`;
