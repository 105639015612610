import { Component } from "react";
import Gutter from "../Gutter";
import GutterAnnotation from "./GutterAnnotation";
import GutterCta from "./GutterCta";
import GutterImage from "./GutterImage";

export default class GutterElement extends Component {
  render() {
    let { __typename, title, overrideContent } = this.props;
    if (overrideContent || !title) {
      return <Gutter>{overrideContent}</Gutter>;
    }
    if (__typename === "ContentfulGutterAnnotation") {
      return <GutterAnnotation {...this.props} />;
    } else if (__typename === "ContentfulGutterCta") {
      return <GutterCta {...this.props} />;
    } else if (__typename === "ContentfulGutterImage") {
      return <GutterImage {...this.props} />;
    } else {
      return null;
    }
  }
}
