import { Component } from "react";
import styled from "styled-components";
import Gutter from "../Gutter";
import { Font, media, rem, Color } from "../../utils/style";
import MagicLink, { magicUrl } from "../MagicLink";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

const StyledGutter = styled(Gutter)``;

const Annotation = styled.div`
  position: relative;
  top: ${(p) => {
      return p.top ? `${p.top}px` : "45px";
    }}
    ${media.tablet`
        position: static;
     `};
`;

const CtaCopy = styled.p`
  ${Font.dutch}
  color: ${Color.ritualBlue};
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  font-style: italic;
  text-align: left;
  letter-spacing: 0;
  padding: 24px;
  margin: 0;

  ${media.tablet`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
   `}
`;

const CtaContent = styled.div`
  border: 2px solid ${Color.ritualBlue};
  display: flex;
  align-items: flex-end;
  justify-content: left;
  transition: color 200ms;

  * {
    transition: color 200ms;
  }

  &:hover {
    cursor: pointer;
    color: white;

    * {
      color: white;
    }

    ${CtaCopy} {
      color: ${Color.ritualBlue};
    }
  }

  ${media.tablet`
       margin: 30px 0;
   `};
`;

const Box = styled.div`
  padding: 0;
  width: 100%;
`;

const CategoryName = styled.h2`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(18)};
  font-weight: 300;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: ${rem(28)};
`;

const ArticleName = styled.h2`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(30)};
  font-weight: 500;
  text-align: left;
  letter-spacing: 0;
  line-height: ${rem(36)};
  margin: 0;

  ${media.tablet`
      font-size: ${rem(26)};
      line-height: ${rem(36)};
   `}
`;

const ImageArea = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-end;
  position: relative;

  ${media.tablet`
      height: 250px;
   `};
`;

const ImageContent = styled.div`
  margin: 24px;
  z-index: 1;
`;

export default class GutterImage extends Component {
  renderContent() {
    let { title, shortCopy, article, image } = this.props;
    return (
      <CtaContent>
        <Box>
          {shortCopy && <CtaCopy>{shortCopy}</CtaCopy>}
          <ImageArea>
            <GatsbyImage
              className="callout-image"
              image={getImage(image)}
              loading="eager"
              alt={title}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
            />
            <ImageContent>
              <CategoryName>{article.category.title}</CategoryName>
              <ArticleName>{article.title}</ArticleName>
            </ImageContent>
          </ImageArea>
        </Box>
      </CtaContent>
    );
  }

  render() {
    let {
      title,
      article,
      top,
      overrideContent,
      className,
      hideGutter,
    } = this.props;
    if (overrideContent || !title) {
      return <Gutter>{overrideContent}</Gutter>;
    }
    return (
      <StyledGutter hideGutter={hideGutter} className={className}>
        <Annotation top={top}>
          {article ? (
            <MagicLink href={magicUrl(article)} ariaLabel={article.title}>
              {this.renderContent()}
            </MagicLink>
          ) : (
            this.renderContent()
          )}
        </Annotation>
      </StyledGutter>
    );
  }
}

export const myQuery = graphql`
  fragment gutterImageSummary on ContentfulGutterImage {
    __typename
    title
    shortCopy
    article {
      __typename
      title
      slug
      category {
        title
      }
    }
    image {
      file {
        url
      }
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: DOMINANT_COLOR
        height: 300
        quality: 90
      )
    }
    backgroundColor
  }
`;
