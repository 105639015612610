import { Component } from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Utils
import { Color, Font, media, rem, responsive } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import { isBright } from "../../utils/luma";

// Components
import RitualButton from "../global/RitualButton";
import Row from "../Row";
import ArticleReadProgress from "./ArticleReadProgress";
import Text from "../Text";

// Styled Elements
const HeroRow = styled(Row)`
  height: 100%;
`;

const HeroColumn = styled.div.attrs({
  className: "col-12 col-sm-9 offset-sm-1 col-md-6 offset-md-5 d-flex",
})``;

const HeroContent = styled.div.attrs({
  className: "align-self-end",
})`
  margin-bottom: 40px;
  padding-left: 15px;
  z-index: 2;

  ${responsive.sm`
    margin-bottom: 60px;
    padding-left: unset;
  `}
`;

const Category = styled.span`
  ${Font.circular}
  color: ${(p) => {
    return p.color ? p.color : Color.ritualBlue;
  }};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
`;

const Title = styled.h1`
  ${Font.circular}
  color: ${(p) => {
    return p.color ? p.color : Color.ritualBlue;
  }};
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  letter-spacing: -0.4px;
  font-weight: 400;
  text-align: left;
  margin: ${rem(16)} 0;

  ${responsive.md`
    font-size: ${rem(34)};
    line-height: ${rem(40)};
    letter-spacing: -1.4px;
  `};

  ${responsive.lg`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1.4px;
  `};
`;

const ReadingTime = styled.span`
  ${Font.circular}
  color: ${(p) => {
    return p.color ? p.color : Color.ritualBlue;
  }};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  text-align: left;
  letter-spacing: 0;
  margin: 0;

  ${media.tablet`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `}
`;

const PlayButton = styled(RitualButton)`
  i {
    margin-left: 8px;

    svg {
      color: #fff;
      transition: color 0.2s;

      g,
      path {
        fill: currentColor !important;
      }
    }
  }

  &:hover {
    i {
      svg {
        color: ${Color.ritualBlue};
      }
    }
  }
`;

export default class ArticleImageHero extends Component {
  render() {
    const {
      title,
      category,
      heroBackgroundColor,
      heroSecondaryColor,
      readTime,
      onClick,
      articleVideo,
    } = this.props;

    const darkOrBright = isBright(heroBackgroundColor) ? "dark" : "bright";
    let lengthInMinutes = undefined;

    if (articleVideo) {
      lengthInMinutes = intl.t("hub.hero.video-length", "{length} min video", {
        length: articleVideo.videoLength / 60,
      });
    }

    return (
      <>
        <HeroRow>
          <HeroColumn>
            <HeroContent>
              <Category color={heroSecondaryColor}>{category.title}</Category>
              <Title color={heroSecondaryColor}>{title}</Title>
              <div
                className="d-flex align-items-center justify-content-between"
                style={articleVideo && { paddingTop: "8px" }}
              >
                {articleVideo && (
                  <PlayButton
                    className="alt-hover"
                    variant={darkOrBright}
                    onClick={onClick.bind(this)}
                    isLink={false}
                  >
                    <Text
                      id="hub.hero.play-video"
                      defaultMessage="Play Video"
                    />{" "}
                    <i>
                      <span className="isvg">
                        <Icons.Play />
                      </span>
                    </i>
                  </PlayButton>
                )}
                {lengthInMinutes ? (
                  <ReadingTime color={heroSecondaryColor}>
                    {lengthInMinutes}
                  </ReadingTime>
                ) : (
                  <ReadingTime color={heroSecondaryColor}>
                    <Text
                      id="hub.hero.reading-time"
                      defaultMessage="{readTime} min read"
                      values={{ readTime }}
                    />
                  </ReadingTime>
                )}
              </div>
            </HeroContent>
          </HeroColumn>
        </HeroRow>
        <ArticleReadProgress />
      </>
    );
  }
}
