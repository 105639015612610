export function isBright(color) {
  let luma = undefined;
  if (color) {
    let c = color.substring(1);
    let rgb = parseInt(c, 16);
    let r = (rgb >> 16) & 0xff;
    let g = (rgb >> 8) & 0xff;
    let b = (rgb >> 0) & 0xff;

    luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }
  return luma > 190;
}
